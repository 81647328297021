import React from "react";
// import { Link } from "gatsby";
import PropTypes from "prop-types";

// import iconLeft from "../../assets/svg/arrow-left.svg";
// import iconRight from "../../assets/svg/arrow-right.svg";

import * as styles from "./FullColumnContentCell.module.scss";

const FullColumnContentCell = (props) => {
    return (
        <div className="grid-container fluid">
            <div className="grid-x grid-margin-x">
                <div className="cell">
                    <div className={styles.fullColumnContentCell}>
                        <div className={[styles.contentWrapper, "js-reveal"].join(" ")}>
                            <div
                                className={styles.content}
                                dangerouslySetInnerHTML={{
                                    __html: props.content,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FullColumnContentCell.propTypes = {
    content: PropTypes.string.isRequired,
};

export default FullColumnContentCell;
