import React from "react";
import ContentCell from "./ContentCell";
import FullWidthContentCell from "./FullWidthContentCell";
import ImageCell from "./ImageCell";
import PropTypes from "prop-types";
import Video from "../Video";

const TYPES = {
  Column1: {
    ContentEditor: "WpPost_Postcontent_Layout_TwoColumnLayout_Column1Content_ContentEditor",
    OneImage: "WpPost_Postcontent_Layout_TwoColumnLayout_Column1Content_OneImage",
    OneVideo: "WpPost_Postcontent_Layout_TwoColumnLayout_Column1Content_OneVideo",
  },
  Column2: {
    ContentEditor: "WpPost_Postcontent_Layout_TwoColumnLayout_Column2Content_ContentEditor",
    OneImage: "WpPost_Postcontent_Layout_TwoColumnLayout_Column2Content_OneImage",
    OneVideo: "WpPost_Postcontent_Layout_TwoColumnLayout_Column2Content_OneVideo",
  },
};

const ColumnContent = props => {
  const {content, pageContext} = props;

  if (!content) return null;

  return (
    <>
      {content.map((item, index) => {
        const {__typename: type} = item;
        switch (type) {
          case TYPES.Column1.ContentEditor:
            return <ContentCell key={index} content={item.description} date={props.date} nextPage={props.nextPage} previousPage={props.previousPage} isFirstContentSection={props.isFirstContentSection} isHomeLayout={props.isHomeLayout} pageContext={pageContext} />;
          case TYPES.Column2.ContentEditor:
            return <FullWidthContentCell key={index} content={item.description} date={props.date} nextPage={props.nextPage} previousPage={props.previousPage} isFirstContentSection={props.isFirstContentSection} pageContext={pageContext} />;
          case TYPES.Column1.OneImage:
          case TYPES.Column2.OneImage:
            if (item.image1 && item.image1.localFile) {
              return <ImageCell key={index} image={item.image1.localFile} pageContext={pageContext} />;
            }
            return null;
          case TYPES.Column1.OneVideo:
          case TYPES.Column2.OneVideo:
            const {desktopVideoUrl, mobileVideoUrl} = item;
            return <Video key={index} desktopVideoUrl={desktopVideoUrl} mobileVideoUrl={mobileVideoUrl} />;
          default:
            return null;
        }
      })}
    </>
  );
};

ColumnContent.propTypes = {
  content: PropTypes.array,
  date: PropTypes.string,
  nextPage: PropTypes.object,
  previousPage: PropTypes.object,
  isFirstContentSection: PropTypes.bool,
  isHomeLayout: PropTypes.bool,
  pageContext: PropTypes.object.isRequired,
};

export default ColumnContent;
