import React from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";
import {Localise, Keys} from "../../utils/translations";
import iconLeft from "../../assets/svg/arrow-left.svg";
import iconRight from "../../assets/svg/arrow-right.svg";

import * as styles from "./ContentCell.module.scss";

const ContentCell = props => {
  const {
    pageContext: {languageCode},
  } = props;

  const renderFirstContentInfo = () => {
    return (
      <>
        <div className="js-reveal-container">
          {props.isFirstContentSection && <div className={[styles.date, "js-reveal"].join(" ")}>{props.date && <div>{props.date}</div>}</div>}
          {!props.isFirstContentSection && <div className={[styles.date, "js-reveal show-for-large"].join(" ")}>{props.date && <div>{props.date}</div>}</div>}
        </div>

        <div className={styles.navLinks}>
          {props.previousPage && (
            <div className={[styles.previousLink, "js-reveal-container"].join(" ")}>
              <Link to={props.previousPage.uri} className="button hollow js-reveal">
                <img className="nav-svg-icon icon-prev" src={iconLeft} width={26} alt="" /> {Localise(Keys.PREVIOUS, languageCode)}
              </Link>
            </div>
          )}
          {props.nextPage && (
            <div className={[styles.nextLink, "js-reveal-container"].join(" ")}>
              <Link to={props.nextPage.uri} className="button hollow js-reveal">
                {Localise(Keys.NEXT, languageCode)} <img className="nav-svg-icon icon-next" src={iconRight} width={26} alt="" />
              </Link>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderLeftInfo = () => {
    return (
      <div className="js-slide-reveal-container">
        <div className="js-slide-reveal">
          <div className={[styles.infoTitle, "h1"].join(" ")}>Articles</div>
        </div>
      </div>
    );
  };

  const classes = [styles.contentCell];

  if (props.isHomeLayout) {
    classes.push(styles.homeLayout);
  }

  return (
    <div className={classes.join(" ")}>
      <div className={styles.contentWrapper}>
        <div className={styles.infoSection}>
          {props.isHomeLayout && renderLeftInfo()}
          {!props.isHomeLayout && renderFirstContentInfo()}
        </div>
        <div className={styles.content}>
          <div className="js-reveal">
            {/* TODO: Enable this - {props.isHomeLayout && props.isFirstContentSection && props.date && <div className={styles.date}>{props.date}</div>} */}
            <div dangerouslySetInnerHTML={{__html: props.content}} />
          </div>
        </div>
      </div>
    </div>
  );
};

ContentCell.propTypes = {
  content: PropTypes.string,
  date: PropTypes.string,
  nextPage: PropTypes.object,
  previousPage: PropTypes.object,
  isFirstContentSection: PropTypes.bool,
  isHomeLayout: PropTypes.bool,
  pageContext: PropTypes.object.isRequired,
};

export default ContentCell;
