import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Video = ({ mobileVideoUrl, desktopVideoUrl }) => {
  const [windowDimensions, setWindowDimensions] = useState(null);

  const windowResized = () => {
    setWindowDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    windowResized();
    window.addEventListener("resize", windowResized);
    return () => {
      window.removeEventListener("resize", windowResized);
    };
  }, []);

  let videoUrl = desktopVideoUrl;

  if (windowDimensions && windowDimensions.width < 640) {
    videoUrl = mobileVideoUrl;
  }

  return (
    <video
      autoPlay
      loop
      muted
      playsInline={true}
      style={{ width: "100%", height: "auto" }}
    >
      <source src={videoUrl} />
    </video>
  );
};

Video.propTypes = {
  desktopVideoUrl: PropTypes.string.isRequired,
  mobileVideoUrl: PropTypes.string,
};

export default Video;
