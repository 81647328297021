import React from 'react';
import FullWidthImageCell from './FullWidthImageCell';
import ImageCell from './ImageCell';
import PropTypes from 'prop-types';

import * as styles from './FullWidthLayout.module.scss';
import FullColumnContentCell from './FullColumnContentCell';
import Video from '../Video';

const TYPES = {
  ContentEditor: 'WpPost_Postcontent_Layout_FullWidthLayout_FullWidthContent_ContentEditor',
  OneImage: 'WpPost_Postcontent_Layout_FullWidthLayout_FullWidthContent_OneImage',
  TwoImage: 'WpPost_Postcontent_Layout_FullWidthLayout_FullWidthContent_TwoImages',
  OneVideo: 'WpPost_Postcontent_Layout_FullWidthLayout_FullWidthContent_OneVideo',
};

const FullWidthLayout = (props) => {
  const { content } = props;
  const { fullWidthContent } = content;

  return (
    <div className={styles.fullWidthLayout}>
      {fullWidthContent &&
        fullWidthContent.map((item, index) => {
          const { __typename: type } = item;
          switch (type) {
            case TYPES.ContentEditor:
              return <FullColumnContentCell key={index} content={item.description} />;

            case TYPES.OneImage:
              if (item.image1 && item.image1.localFile) {
                return (
                  <div key={index} className={styles.fullWidthImage}>
                    <FullWidthImageCell image={item.image1.localFile} />
                  </div>
                );
              }
              return null;

            case TYPES.TwoImage:
              const {
                image1: { localFile: leftImage },
                image2: { localFile: rightImage },
              } = item;
              return (
                <div
                  key={index}
                  className={['grid-container fluid', styles.doubleImages].join(' ')}
                >
                  <div className="grid-x grid-margin-x">
                    <div
                      className={['cell small-6', styles.bottomMarginSmall, styles.leftImage].join(
                        ' ',
                      )}
                    >
                      <ImageCell image={leftImage} />
                    </div>
                    <div className={['cell small-6', styles.rightImage].join(' ')}>
                      <ImageCell image={rightImage} />
                    </div>
                  </div>
                </div>
              );

            case TYPES.OneVideo:
              const { desktopVideoUrl, mobileVideoUrl } = item;
              return (
                <div key={index} className={styles.oneVideo}>
                  <Video desktopVideoUrl={desktopVideoUrl} mobileVideoUrl={mobileVideoUrl} />
                </div>
              );
            default:
              return null;
          }
        })}
    </div>
  );
};

FullWidthLayout.propTypes = {
  content: PropTypes.object.isRequired,
};

export default FullWidthLayout;
