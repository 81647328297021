import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import * as styles from "./PostGridItem.module.scss";

const PostGridItem = (props) => {
  const image = getImage(props.image);

  const classes = [styles.postGridItem];

  if (props.className) {
    classes.push(props.className);
  }

  const renderImage = (image) => {
    if (image) {
      return <GatsbyImage image={image} alt="" />;
    }

    return (
      <StaticImage
        src="../../assets/images/placeholder.svg"
        layout="fullWidth"
        aspectRatio={1}
        backgroundColor="#CCCCCC"
        alt="Aspect"
      />
    );
  };

  return (
    <div className={classes.join(" ")}>
      <div className="js-reveal-image">
        <Link to={props.uri}>{renderImage(image)}</Link>
      </div>
      <div className={styles.content}>
        <div className="js-reveal-container">
          <div className={[styles.date, "js-reveal"].join(" ")}>
            {props.date}
          </div>
        </div>
        <div className="js-reveal-container">
          <Link to={props.uri}>
            <div
              className={[styles.title, "secondary-font js-reveal"].join(" ")}
            >
              {props.title}
            </div>
            {props.excerpt && (
              <div
                className={[styles.excerpt, "js-reveal"].join(
                  " "
                )}
              >
                <div dangerouslySetInnerHTML={{__html: props.excerpt}} />
              </div>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

PostGridItem.propTypes = {
  image: PropTypes.object,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
};

export default PostGridItem;
