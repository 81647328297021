import React from "react";
import ColumnContent from "./ColumnContent";
import PropTypes from "prop-types";

import * as styles from "./TwoColumnLayout.module.scss";

const TwoColumnLayout = (props) => {
  const { content, pageContext } = props;
  const { column1Content, column2Content } = content;

  return (
    <div className={styles.twoColumnLayout}>
      <div className="grid-container fluid">
        <div className="grid-x grid-margin-x">
          {column1Content && (
            <div className={["cell large-6", styles.column].join(" ")}>
              <ColumnContent
                content={column1Content}
                date={props.date}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                isFirstContentSection={props.isFirstContentSection}
                isHomeLayout={props.isHomeLayout}
                pageContext={pageContext}
              />
            </div>
          )}
          {column2Content && (
            <div className={["cell large-6", styles.column].join(" ")}>
              <ColumnContent
                content={column2Content}
                pageContext={pageContext}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TwoColumnLayout.propTypes = {
  content: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  nextPage: PropTypes.object,
  previousPage: PropTypes.object,
  isFirstContentSection: PropTypes.bool.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default TwoColumnLayout;
