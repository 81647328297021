import React from "react";
import { StaticQuery, graphql } from "gatsby";

import * as styles from "./DefaultFeaturedMediaImage.module.scss";

import placeholderImage from "../assets/images/placeholder.svg";

const DefaultFeaturedMediaImage = (props) => {
  const style = {
    backgroundColor: "#CCCCCC",
  };

  const imageClasses = [styles.image];

  if (props.fullHeightOnMobile === true) {
    imageClasses.push(styles.fullHeight);
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => {
        return (
          <div className={[styles.defaultFeaturedMediaImage, "js-reveal-image"].join(" ")}>
            <img
              className={imageClasses.join(" ")}
              src={placeholderImage}
              alt={data.site.siteMetadata.title}
              style={style}
            />
          </div>
        );
      }}
    />
  );
};

export default DefaultFeaturedMediaImage;
