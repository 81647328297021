import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./FeaturedMediaImage.module.scss";

const FeaturedMediaImage = (props) => {
  const image = getImage(props.image.localFile);

  const imageClasses = [styles.image];

  if (props.fullHeightOnMobile === true) {
    imageClasses.push(styles.fullHeight);
  }

  return (
    <div className={styles.featuredMediaImage}>
      <GatsbyImage
        className={imageClasses.join(" ")}
        image={image}
        alt={props.image.altText}
      />
    </div>
  );
};

FeaturedMediaImage.propTypes = {
  image: PropTypes.object.isRequired,
  fullHeightOnMobile: PropTypes.bool,
};

export default FeaturedMediaImage;
