import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import * as styles from "./ImageCell.module.scss";

const ImageCell = (props) => {
  const image = getImage(props.image);

  return (
    <div className={[styles.imageCell, "js-reveal-image"].join(" ")}>
      <GatsbyImage className={styles.image} image={image} alt="" />
    </div>
  );
};

ImageCell.propTypes = {
  image: PropTypes.object,
};

export default ImageCell;
