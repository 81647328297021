import React from "react";
// import { Link } from "gatsby";
import PropTypes from "prop-types";

// import iconLeft from "../../assets/svg/arrow-left.svg";
// import iconRight from "../../assets/svg/arrow-right.svg";

import * as styles from "./FullWidthContentCell.module.scss";

const FullWidthContentCell = (props) => {
    return (
        <div className={styles.fullWidthContentCell}>
            <div className={[styles.contentWrapper, "js-reveal"].join(" ")}>
                <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{ __html: props.content }}
                ></div>
            </div>
        </div>
    );
};

FullWidthContentCell.propTypes = {
    content: PropTypes.string.isRequired,
    date: PropTypes.string,
    nextPage: PropTypes.object,
    previousPage: PropTypes.object,
    isFirstContentSection: PropTypes.bool,
};

export default FullWidthContentCell;
