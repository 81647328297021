// extracted by mini-css-extract-plugin
export var content = "ContentCell-module--content--F+fSc";
export var contentCell = "ContentCell-module--content-cell--AZ6H8";
export var contentWrapper = "ContentCell-module--content-wrapper--vJ+Kp";
export var date = "ContentCell-module--date--WNkef";
export var homeLayout = "ContentCell-module--home-layout--gqKy9";
export var infoSection = "ContentCell-module--info-section--v37Jg";
export var infoTitle = "ContentCell-module--info-title--xAKwH";
export var navLinks = "ContentCell-module--nav-links--O1Dq9";
export var nextLink = "ContentCell-module--next-link---yP9e";
export var previousLink = "ContentCell-module--previous-link--vsF00";