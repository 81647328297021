import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

import * as styles from "./FeaturedMediaVideo.module.scss";

const FeaturedMediaVideo = props => {
  const containerRef = useRef(null);
  const {mobileVideoUrl, desktopVideoUrl} = props;
  const [containerDimensions, setContainerDimensions] = useState(null);
  const [videoDimensions, setVideoDimensions] = useState(null);

  const videoLoaded = event => {
    setVideoDimensions({
      height: event.target.videoHeight,
      width: event.target.videoWidth,
    });
  };

  const windowResized = () => {
    // document.documentElement.style.setProperty("--vh", `${window.innerHeight / 100}px`);
    setContainerDimensions({
      height: containerRef.current.offsetHeight,
      width: containerRef.current.offsetWidth,
    });
  };

  useEffect(() => {
    windowResized();
    window.addEventListener("resize", windowResized);
    return () => {
      window.removeEventListener("resize", windowResized);
    };
  }, []);

  let style = {
    maxHeight: "none",
    maxWidth: "none",
  };

  if (videoDimensions && containerDimensions) {
    const containerRatio = containerDimensions.width / containerDimensions.height;
    const videoRatio = videoDimensions.width / videoDimensions.height;

    if (containerRatio <= videoRatio) {
      style.maxHeight = "100%";
    } else if (containerRatio > videoRatio) {
      style.maxWidth = "100%";
    }
  }

  let videoUrl = desktopVideoUrl;

  if (containerDimensions && containerDimensions.width < 640) {
    videoUrl = mobileVideoUrl;
  }

  const classes = [styles.featuredMediaVideo];

  if (props.fullHeightOnMobile === true) {
    classes.push(styles.fullHeight);
  }

  return (
    <div className={classes.join(" ")} ref={containerRef}>
      <video autoPlay loop muted onLoadedData={videoLoaded} style={style} playsInline={true}>
        <source src={videoUrl} />
      </video>
    </div>
  );
};

FeaturedMediaVideo.propTypes = {
  mobileVideoUrl: PropTypes.string.isRequired,
  desktopVideoUrl: PropTypes.string.isRequired,
  fullHeightOnMobile: PropTypes.bool,
};

export default FeaturedMediaVideo;
